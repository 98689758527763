import React from "react";
import { Helmet } from "react-helmet";

const title =
  "18 things I wish I knew in the first year | Articles | Manchester Students Group";
const description = "";

export default function Blog3(p) {
  return (
    <main className="blog-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <div className="blog-post">
        <img src="/18things.jpg" alt="" />
        <h1>18 THINGS I WISH I KNEW IN FIRST YEAR</h1>
        <p>ISLA MOORE / GUIDE / 2 MINUTE READ</p>

        <ol>
          <li>
            It might be nerve-wracking, but it’s well worth knocking on your
            flatmates’ doors during the daytime in the first few weeks of
            arriving at uni, in the golden period in which nobody will judge you
            for being too eager or friendly. What’s more, the workload is low in
            the first few weeks so you can explore the city or shop for
            essentials with a new friend.
          </li>

          <li>
            This one may seem obvious, but is worth saying anyway; don’t commit
            flatcest. It’s awkward for everyone involved and you really, really
            won’t want to see them every morning over your plate of toast.
            You’ll probably also end up much closer with them if they become a
            friend rather than a potential romance. In other words, don’t sh*t
            where you eat!
          </li>

          <li>
            On the topic of sex, make sure it's always safe, and try not to end
            up in awkward dynamics. It might even be worth lightly background
            checking people before jumping into bed with them…Fallowfield is a
            much smaller world than it seems and the path between Sainsbury’s
            and Oak House is one long jumpscare lane.
          </li>

          <li>
            Almost everyone I know in second-year, including me, wishes they had
            had someone to help them do the boring admin of choosing their
            modules and signing up for societies. Ask the uni, ask online and
            ask your friends for help when you need it!
          </li>

          <li>
            Missing your lectures is a slippery slope – I didn’t listen when I
            was told myself but it is completely true that when you miss one
            seminar or lecture, it becomes twice as easy to miss the next, and
            the next, and by the time the exam rolls around you have created
            double the work for yourself.
          </li>

          <li>
            Bite the bullet and buy a bus pass early – you might be reluctant to
            pay but they only go up in price. You will be getting the bus to and
            from uni and nights out right from the beginning, so there is no
            point delaying.
          </li>

          <li>
            Take lunch with you when you go to uni – you’ll be much less likely
            to fall for the trap of an ever-increasingly-priced meal deal if you
            already have a tasty meal or snack in your bag.
          </li>

          <li>
            You will get ill, over and over again, because there are just that
            many germs floating around, so do yourself a favour and keep stocked
            up on painkillers and Vitamin C.
          </li>

          <li>
            Take advantage of the fact that you only have to pass first-year.
            While it is incredibly rewarding and important to fully engage with
            the academic side of uni, first-year is also the year in which you
            have the most time for fun and relaxation, so say yes to lots of
            plans, and know that it’s fine to then leave early if you aren’t
            really feeling it after all.
          </li>

          <li>
            On the subject of free time, take up a hobby – you may never have
            this much free-time again, so do something productive like learn how
            to drive, get a job, go swimming, or visit family and friends.
          </li>

          <li>
            Hangxiety is a killer; we’ve all woken up after nights out and
            regretted every single thing we said and did, but it is worth
            remembering that everyone is mainly thinking about themselves and
            nobody is going to judge you for getting a little bit silly.
          </li>

          <li>
            Inversely, be careful not to judge other people too much from your
            first experience of them. Not only do people sometimes present their
            worst selves when nervous or drunk, but it is also hard to
            understand what people are really like when they have just arrived
            at uni and are still trying to work out how to fit in. You will make
            friends in the unlikeliest of places.
          </li>

          <li>
            Be prepared for the catwalk that is the first few months of first
            term – if you spend that extra few minutes getting ready, you will
            feel more confident when facing the hordes of students all taking
            part in the unspoken fashion show of campus.
          </li>

          <li>
            If tickets to an event you want to go to is sold out, try Manchester
            Student Group’s Facebook group for people reselling theirs.
          </li>

          <li>
            Find a friend on your course; it makes the lectures so much more fun
            and you will be ten times as motivated to get yourself out of bed
            and into uni in the first place.
          </li>

          <li>
            There will always be houses and people to live with later on in the
            year than you think, so while you should be keeping your second-year
            housing arrangements in mind from the start, don’t stress yourself,
            and be aware that you don’t need to find a house super early and
            risk living with people you don’t actually know that well.
          </li>

          <li>
            It is honestly not worth worrying about where you have been placed
            accommodation-wise at this point. You can find your people wherever
            you are living in first-year, even if you have to look a little
            harder.
          </li>

          <li>
            Have a little grace and patience when people annoy you, and always,
            always look after your friends. They are the most important thing
            you will have.
          </li>
        </ol>
      </div>
    </main>
  );
}
